<template>
    <div class="row">

        <div class="col-md-4">
            <select2
                    :options="comparatorList"
                    :displayLabel="false"
                    size="sm"
                    :model.sync="comparatorLocal"
                    :disabled="isDisabled"
                    :clearable="false"
                    label="i18n"
            />
        </div>

        <div class="col-md-8">
            <select2
                    :options="autocompleteEmployees"
                    :displayLabel="false"
                    size="sm"
                    :model.sync="selectedValueLocal"
                    :disabled="isSelectedValueDisabled"
            />
        </div>

    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import select2 from '../../../input/Select2.vue'
import EmployeeField from '../../../input/Employee.vue'
import i18n from '../../../../libs/i18n'
import useAPI from '../../../../utils/useAPI'
import { isObject } from '../../../../utils/utils'

export default {
  components: {
    select2,
    EmployeeField,
    // InputField
  },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const comparatorLocal = ref({
      value: props.field.filter.comparator,
      i18n: capitalize(i18n.t(props.field.filter.comparator))
    })

    const comparatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const selectedValueLocal = ref({})
    const isMounted = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees } = useAPI()

    const isSelectedValueDisabled = computed(() => {
      if (
        props.isDisabled ||
        (
          comparatorLocal.value != null &&
          (
            comparatorLocal.value.value == 'is_empty' ||
            comparatorLocal.value.value == 'is_not_empty'
          )
        )
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(comparatorLocal, val => {
      if (
        val != null &&
        (
          val.value == 'is_empty' ||
          val.value == 'is_not_empty'
        )) {
        selectedValueLocal.value = null
      }
      parentUpdate()
    })

    watch(() => props.field, val => {
      populateSelectedValueLocal()
    }, { deep: true })

    watch(selectedValueLocal, val => {
      parentUpdate()
    })

    watch(autocompleteEmployees, val => {
      populateSelectedValueLocal()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateSelectedValueLocal = () => {
      if (!isObject(props.field.filter.value) && autocompleteEmployees.value.length != 0) {
        selectedValueLocal.value = autocompleteEmployees.value.find(u => u.id == props.field.filter.value)
      } else if (props.field.filter.value != undefined) {
        selectedValueLocal.value = JSON.parse(JSON.stringify(props.field.filter.value))
      }
    }

    const parentUpdate = () => {
      if (isMounted.value) {
        if (isSelectedValueDisabled.value) {
          let fieldLocal = props.field
          fieldLocal.filter.comparator = comparatorLocal.value.value
          fieldLocal.filter.field = props.field.name
          fieldLocal.filter.value = null

          emit('updateFieldFilter', fieldLocal)
        } else if (
          comparatorLocal.value.value != null &&
          selectedValueLocal.value != null
        ) {
          let fieldLocal = props.field
          fieldLocal.filter.comparator = comparatorLocal.value.value
          fieldLocal.filter.field = props.field.entity ? props.field.entity : props.field.name
          fieldLocal.filter.value = selectedValueLocal.value.id

          emit('updateFieldFilter', fieldLocal)
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateSelectedValueLocal()

    setTimeout(() => {
      isMounted.value = true
    }, 500)

    return {
      // Components
      capitalize,

      // Data
      comparatorLocal,
      comparatorList,
      selectedValueLocal,

      // Computed
      autocompleteEmployees,
      isSelectedValueDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>