<template>
  <div class="col-md-12">
    <b-card class="baseHeaderSortDetails">
      <sort-form
          v-for="(activeSort, index) in activeSortLocal"
          :key="'active_sort_'+index"
          :available-sort-fields="availableSortFields"
          :active-sort.sync="activeSort"

          @removeSort="removeSort(index)"
      />

      <button-add
          size="sm"
          @click="addNewSort"
          :text="$t('AddSort')"
      />
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import SortForm from './components/_SortDetail.vue'
import ButtonAdd from '../../../components/button/Add'

export default {
  components: {
    SortForm,
    ButtonAdd
  },
  props: {
    availableSortFields: {
      type: Array,
      default: () => []
    },
    activeSorts: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // console.log(props.availableSortFields)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const activeSortLocal = computed({
      get () {
        return props.activeSorts
      },
      set (val) {
        emit('update:activeSort', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewSort = () => {
      activeSortLocal.value.push({
        column: null,
        order: 'DESC'
      })
    }

    const removeSort = (index) => {
      activeSortLocal.value.splice(index, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      activeSortLocal,

      // Methods
      addNewSort,
      removeSort
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>