<template>
  <div class="col-md-8">

    <!--      Mobile Search + Views -->
    <div class="d-flex d-md-none mb-1 mb-md-0">

      <!--      Search-->
      <b-input-group class="input-group-merge">
        <b-form-input
            :placeholder="$t('Search')"
            class="search-product"
            @input.native="$emit('update:query', $event.target.value)"
        />
        <b-input-group-append is-text>
          <icon icon="search"
                class="text-muted"/>
        </b-input-group-append>
      </b-input-group>

      <!--      Parameters-->
      <div
          v-if="allowViewSettings"
          class="my-auto d-block d-md-none ml-1"
      >
        <search-button
            class="text-nowrap"
            icon="cog"
            size="sm"
            variant="outline-primary"
            :with-icon="true"
            style="height: 29.22px;"

            @click="$emit('toggleParameterDetailsOpen')"
        />
      </div>

      <!--      Views-->
      <div class="my-auto">
        <b-form-radio-group
            v-if="!readOnly"
            v-model="selectedView"
            class="ml-1 list item-view-radio-group my-auto"
            buttons
            size="sm"
            button-variant="outline-primary"
            @input.native="$emit('update:selectedView', $event.target.value)"
        >
          <b-form-radio
              v-for="option in displayAvailableViews"
              :key="option.value"
              :value="option.value"
          >
            <icon
                :icon="option.icon"
            />
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <!--    Web + mobile remaining -->
    <div class="d-flex justify-content-between mb-1 mb-md-0">

      <!--      Sorts-->
      <div class="my-auto mr-2">
        <search-button
            class="text-nowrap"
            icon="arrows-alt-v"
            size="sm"
            variant="outline-primary"
            :title="capitalize($tc('sort', 2)) + ' ('+activeSorts.length+')'"

            @click="$emit('toggleSortDetailsOpen')"
        />
      </div>

      <!--      Filters-->
      <div class="my-auto mr-2">
        <search-button
            class="text-nowrap"
            icon="filter"
            size="sm"
            variant="outline-primary"
            :title="capitalize($tc('filter', 2)) + ' ('+numberOfFilters+')'"

            @click="$emit('toggleFilterDetailsOpen')"
        />
      </div>

      <!--      Parameters-->
      <div
          v-if="allowViewSettings"
          class="my-auto d-none d-md-block mr-2"
      >
        <search-button
            class="text-nowrap"
            icon="cog"
            size="sm"
            variant="outline-primary"
            :with-icon="true"
            style="height: 29.22px;"

            @click="$emit('toggleParameterDetailsOpen')"
        />
      </div>

      <!--      Search-->
      <div class="d-none d-md-block my-auto w-100 mr-2">
        <b-input-group class="input-group-merge" size="sm">
          <b-form-input
              :placeholder="$t('Search')"
              class="search-product"
              v-model="query"
              @input.native="$emit('update:query', $event.target.value)"
          />
          <b-input-group-append is-text>
            <icon icon="search"
                  class="text-muted"/>
          </b-input-group-append>
        </b-input-group>
      </div>

      <!--      Views-->
      <div class="d-none d-md-block my-auto mr-2">
        <b-form-radio-group
            v-if="!readOnly"
            v-model="selectedView"
            class="list item-view-radio-group my-auto"
            buttons
            size="sm"
            button-variant="outline-primary"
            @input.native="$emit('update:selectedView', $event.target.value)"
        >
          <b-form-radio
              v-for="option in displayAvailableViews"
              :key="option.value"
              :value="option.value"
          >
            <icon
                :icon="option.icon"
            />
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <!--      Add buttons-->
      <div class="my-auto">
          <div class="d-flex">
            <add-button
                @click="$emit('addItem')"
                v-if="allowAdd"
                :withIcon="true"
                :isLoading="isAddButtonsLoading"
                :text="textButtonAdd"
                :is-disabled="isButtonAddDisabled"
                size="sm"
            />

            <upload-button
                @click="$emit('addItemByUpload')"
                v-if="allowAddByUpload"
                :withText="false"
                :withIcon="true"
                :isLoading="isAddButtonsLoading"
                class="ml-50"
                size="sm"
                style="height: 29.22px;"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { getUserData } from '../../../utils/utils'

import SearchButton from '../../button/Button.vue'
import AddButton from '../../button/Add.vue'
import UploadButton from '../../button/Upload.vue'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'

export default {
  components: {
    SearchButton,
    AddButton,
    UploadButton,
  },
  props: {
    selectedFilters: {
      type: Array,
      default: () => []
    },
    activeSorts: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: ''
    },
    selectedView: {
      type: String,
    },
    allowViewSettings: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const availableViews = ref([])
    const defaultView = ref({})
    // const selectedView = ref(null)
    const query = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { moduleViews } = useAPI()

    const displayAvailableViews = computed(() => {
      let displayAvailableViews = []

      availableViews.value.forEach(v => {
        if (v.type == 'list' && displayAvailableViews.some(v => v.value == 'list') === false) {
          displayAvailableViews.push({
            value: 'list',
            icon: 'list'
          })
        } else if (v.type == 'grid' && displayAvailableViews.some(v => v.value == 'grid') === false) {
          displayAvailableViews.push({
            value: 'grid',
            icon: 'th-large'
          })
        } else if (v.type == 'kanban' && displayAvailableViews.some(v => v.value == 'kanban') === false) {
          displayAvailableViews.push({
            value: 'kanban',
            icon: 'pause'
          })
        }

      })

      return displayAvailableViews
    })

    const numberOfFilters = computed(() => {
      let numberOfFilters = 0

      props.selectedFilters.forEach(f => {
        numberOfFilters += f.group.length
      })

      return numberOfFilters
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(moduleViews, val => {
      updateViews()
    })


    watch(query, val => {
      emit('updateFilters', [{
        name: 'search',
        filter: {
          comparator: 'contains',
          field: '_all',
          value: val
        }
      }])
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateViews = () => {
      availableViews.value = store.getters['moduleView/get' + capitalize(props.module) + 'ModuleViews']
      defaultView.value = store.getters['moduleView/getModuleView'](getUserData().defaultViews[props.module].id)

      if (defaultView.value) {
        emit('update:selectedView', defaultView.value.type)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateViews()

    return {
      // Components
      capitalize,

      // Data
      // selectedView,
      query,

      // Computed
      displayAvailableViews,
      numberOfFilters,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>