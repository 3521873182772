<template>
  <div>
    <b-card no-body>
      <b-table
          responsive
          :items="items"
          :fields="columnsLocal"
          :tbody-tr-class="rowClass"
          :no-local-sorting="true"
          :selectable="['single', 'multi'].includes(selectable)"
          :select-mode="selectable"
          striped
          hover
          @sort-changed="tableSort"
          @row-clicked="$emit('selectItem', $event)"
          @row-selected="selected = $event"
      >
        <!--              Column head-->
        <template
            v-for="(tableColumn, index) in columnsLocal"
            v-slot:[`head(${tableColumn.key})`]="data">

          <slot
              :name="'listView_head_'+tableColumn.key"
              :item="data.field.display"
          >
            <div v-if="'cell' in data.field && data.field.cell.isSelect && selectable == 'multi'">
              <b-form-checkbox v-model="selectAll"/>
            </div>
            <div v-else>
              <span v-if="data.field.translateTitle">
                {{ $tc(data.field.title) }}
              </span>
              <span v-else>{{ data.field.title }}</span>
            </div>

          </slot>

        </template>

        <!--              Cell-->
        <template
            v-for="(tableColumn, index) in columnsLocal"
            v-slot:[`cell(${tableColumn.key})`]="data">

            <div v-if="tableColumn.key == 'actions'" class="text-nowrap">
                <div v-if="allowViewWindows">
                    <!--          View-->
                    <button-view
                        @click.native.stop="$emit('showDetails', data.item)"
                        v-b-tooltip.hover.left="$t('View')"
                        :withIcon="true"
                        :withText="false"
                        :withBorder="false"
                        size="sm"
                    />

                    <slot
                        name="listView_cell_actions"
                        :item="data.item"
                    >
                        <div class="text-nowrap">
                            {{ data.item[tableColumn.key] }}
                        </div>
                    </slot>

                </div>
                <slot
                    v-else
                    name="listView_cell_actions"
                    :item="data.item"
                >
                    <div class="text-nowrap">
                        {{ data.item[tableColumn.key] }}
                    </div>
                </slot>
            </div>

          <slot
              v-else
              :name="'listView_cell_'+tableColumn.key"
              :item="data.item"
          >
            <!--                Custom cell-->
            <div
                v-if="'cell' in tableColumn"
                class="text-nowrap"
                :class="{'text-center': tableColumn.cell.center}"
            >
              <!--                  Select-->
              <span v-if="tableColumn.cell.isSelect && selectable == 'multi'">
                <b-form-checkbox v-model="selected"
                                 :value="data.item"/>
              </span>

              <!-- Currency-->
              <div
                  v-if="tableColumn.cell.isCurrency"
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
                {{ currency(data.item[tableColumn.key]) }}
              </div>

              <!-- Date-->
              <div
                  v-else-if="tableColumn.cell.isDate"
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
                {{ data.item[tableColumn.key]|moment('L') }}
              </div>

              <!-- Date time-->
              <div
                  v-else-if="tableColumn.cell.isDateTime"
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
                {{ data.item[tableColumn.key]|moment('LLL') }}
              </div>

              <!-- use Display-->
              <div
                  v-else-if="tableColumn.cell.useDisplay"
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
<!--                  <pre>{{data.item}}</pre>-->
                <span v-if="tableColumn.cell.translate">{{ $t(data.item[tableColumn.key]._display) }}</span>
                <span v-else-if="data.item[tableColumn.key] != null && ('_display' in data.item[tableColumn.key])">{{ data.item[tableColumn.key]._display }}</span>
                  <span v-else>-</span>
              </div>

              <!-- Translate-->
              <div
                  v-else-if="tableColumn.cell.translate"
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
                {{ $t(data.item[tableColumn.key]) }}
              </div>

              <div
                  v-else
                  class="text-nowrap"
                  :class="{'text-center': tableColumn.cell.center}"
              >
                {{ data.item[tableColumn.key] }}
              </div>

            </div>

            <div
                v-else
                class="text-nowrap"
            >
              {{ data.item[tableColumn.key] }}
            </div>

          </slot>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isSameObjectsContent } from '../../../utils/utils'
import { currency, capitalize } from '../../../utils/filter'

import i18n from '../../../libs/i18n'
import store from '../../../store'
import moment from 'moment'
import ButtonView from '../../button/View.vue'

export default {
  components: { ButtonView },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    allowViewWindows: {
      type: Boolean,
      default: false
    },
    currentView: {
      type: Object,
      default: () => {}
    },
    isActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.currentView)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selected = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    const selectAll = computed({
      get () {
        return isSameObjectsContent(selected.value, props.items)
        // return props.items.length ? selected.value.length == props.items.length : false
      },
      set (val) {
        if (val) {
          selected.value = JSON.parse(JSON.stringify(props.items))
        } else if (!val && isSameObjectsContent(selected.value, props.items)) {
          selected.value = []
        }
      }
    })

    const sortColumn = computed(() => {
      if (props.currentView.sorts.length) {
        return props.currentView.sorts[0].column
      } else {
        return null
      }
    })

    const sortOrder = computed(() => {
      if (props.currentView.sorts.length) {
        return props.currentView.sorts[0].order
      } else {
        return null
      }
    })

    const columnsLocal = computed(() => {
      let columnsLocal = (props.currentView && ('listColumns' in props.currentView)) ? props.currentView.listColumns : []
      if (props.isActionColumnEnabled) {
        columnsLocal.push({ key: 'actions', title: i18n.tc('action', 2) })
      }

      return columnsLocal
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selected, val => {
      console.log(val)
      emit('rowSelected', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const rowClass = (item) => {
      if (item._isDisabled == true) return 'table-secondary'
    }

    const tableSort = (ctx) => {
      emit('listSort', {
        column: ctx.sortBy,
        order: ctx.sortDesc ? 'DESC' : 'ASC'
      })
    }

    const resetSelected = () => {
      selected.value = []
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // if (props.isActionColumnEnabled) {
    //   columnsLocal.value.push({ key: 'actions', display: i18n.tc('action', 2) })
    // }

    if (props.selectable == 'multi') {
      columnsLocal.value.unshift({
        display: '',
        key: 'select',
        sortable: false,
        cell: {
          isSelect: true
        }
      })
    }

    return {
      // Components
      currency,
      capitalize,

      // Data
      columnsLocal,
      selected,

      // Computed
      selectAll,
      sortColumn,
      sortOrder,

      // Methods
      rowClass,
      tableSort,
      resetSelected,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>