<template>
  <b-row>

    <!--          Opportunity-->
    <b-col md="8">
      <field-input
          name="opportunity"
          :rules="'required'"
          :model.sync="workflow.opportunity"
      />
    </b-col>

    <!--          Responsible-->
    <b-col md="4">
      <field-employee
          :name="$t('responsible')"
          :model.sync="workflow.responsible"
          :isRequired="true"
          :withMe="true"
      />
    </b-col>

    <!--          Contact-->
    <b-col md="6">
      <contact2
          :is-initial-contact-company="true"
          :is-required="true"

          @updateCompany="workflow.customerCompany=$event"
          @updateIndividual="workflow.customerIndividual = $event"
      />
<!--      <field-contact-->
<!--          :customerCompany.sync="workflow.customerCompany"-->
<!--          :customerIndividual.sync="workflow.customerIndividual"-->
<!--          :isRequired="true"-->
<!--      />-->
    </b-col>

    <!--          opportunity date-->
    <b-col md="6">
      <field-date
          name="opportunityDate"
          :model.sync="workflow.opportunityDate"
          :isRequired="true"
      />
    </b-col>

    <!--    &lt;!&ndash;          start date&ndash;&gt;-->
    <!--    <b-col md="4">-->
    <!--      <field-date-->
    <!--          :name="'startDate'"-->
    <!--          :model.sync="workflow.startDate"-->
    <!--          :isRequired="true"-->
    <!--      />-->
    <!--    </b-col>-->

    <!--          closing date-->
    <!--    <b-col md="4">-->
    <!--      <field-date-->
    <!--          :name="'closingDate'"-->
    <!--          :model.sync="workflow.closingDate"-->
    <!--          :minDate="workflow.startDate"-->
    <!--      />-->
    <!--    </b-col>-->

    <b-col
        cols="12"
        class="mt-md-2"
    >
      <h1>{{ capitalize($tc('phase', 2)) }}</h1>
    </b-col>

    <b-col
        cols="12"
        class="mt-2 mt-md-0"
    >
      <form-phases
          :phases.sync="workflow.phases"
      />
    </b-col>


    <b-col
        cols="12"
        class="mt-2"
    >
      <h1>{{ $t('Objectifs') }}</h1>
    </b-col>

    <!--          Potential expenses-->
    <b-col md="6">
      <field-input
          :name="'potentialExpenses'"
          :model.sync="workflow.potentialExpenses"
          :isGroup="true"
      >
        <template #prepend>
          €
        </template>
      </field-input>
    </b-col>

    <!--          Potential turnover-->
    <b-col md="6">
      <field-input
          :name="'potentialTurnover'"
          :model.sync="workflow.potentialTurnover"
          :isGroup="true"
      >
        <template #prepend>
          €
        </template>
      </field-input>
    </b-col>

    <!--          Potential time spent-->
    <b-col md="6">
      <field-input
          :name="'potentialTimeSpentInHours'"
          :model.sync="workflow.potentialTimeSpent"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import FieldInput from '@/components/input/Input'
import FieldContact from '@/components/input/Contact'
import Contact2 from '../input/Contact2.vue'
import FieldDate from '@/components/input/Date'
import FormPhases from '@/components/form/phases/Phases'
import FieldEmployee from '../input/Employee'

export default {
  components: {
    FieldInput,
    FieldContact,
    FieldDate,
    FormPhases,
    FieldEmployee,
    Contact2
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const selectedContact = computed(() => {
      if (props.workflow.customerCompany) {
        return props.workflow.customerCompany
      } else if (props.workflow.customerIndividual) {
        return props.workflow.customerIndividual
      } else {
        return null
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data

      // Computed
      selectedContact,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>