<template>
  <div class="col-md-12">
    <b-card class="baseHeaderFilterDetails">
      <div class="row">

        <div class="col-md-6">
          <h1>Filtres rapides</h1>
          <b-form-group
              v-for="(fieldFilter, indexQuickFilter) in quickFiltersList"
              :key="'quickFilter_'+fieldFilter.name"
              class="mb-50 ml-2"
          >
            <template #label
                      class="pb-0">
              <b-form-checkbox
                  v-model="selectedFiltersLocal"
                  :value="fieldFilter.name"
                  :disabled="fieldFilter.isDisabled"
              >
                {{ capitalize($tc(fieldFilter.name)) }}
              </b-form-checkbox>
            </template>


          </b-form-group>

        </div>

        <div class="col-md-6">
          <h1>Filtres par champs</h1>

          <b-form-group
              v-for="(fieldFilter, index) in fieldFiltersList"
              :key="'fieldFilter_'+fieldFilter.name"
              class="mb-50 ml-2"
          >
            <template #label
                      class="pb-0">
              <b-form-checkbox
                  v-model="selectedFiltersLocal"
                  :value="fieldFilter.name"
                  :disabled="fieldFilter.isDisabled"
              >
                {{ capitalize($tc(fieldFilter.name)) }}
              </b-form-checkbox>
            </template>

            <transition name="fade">
              <div
                  v-if="selectedFiltersLocal.includes(fieldFilter.name)"
                  class="checkBoxDetails"
              >
                <filter-detail-text
                    v-if="fieldFilter.type == 'text'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"

                    @updateFieldFilter="updateFiltersLocal($event)"
                />
                <filter-detail-select
                    v-else-if="fieldFilter.type == 'list'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"

                    @updateFieldFilter="updateFiltersLocal($event)"
                />
                <filter-detail-entity
                    v-else-if="fieldFilter.type == 'entity'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"


                    @updateFieldFilter="updateFiltersLocal($event)"
                />
                <filter-detail-employee
                    v-else-if="fieldFilter.type == 'employee'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"

                    @updateFieldFilter="updateFiltersLocal($event)"
                />
                <filter-detail-date
                    v-else-if="fieldFilter.type == 'date'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"


                    @updateFieldFilter="updateFiltersLocal($event)"
                />
                <filter-detail-number
                    v-else-if="fieldFilter.type == 'number'"
                    :field="fieldFilter"
                    :isDisabled="fieldFilter.isDisabled"


                    @updateFieldFilter="updateFiltersLocal($event)"
                />
              </div>
            </transition>
          </b-form-group>

        </div>

      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import FilterDetailText from './components/_FilterDetailText.vue'
import FilterDetailSelect from './components/_FilterDetailSelect.vue'
import FilterDetailEntity from './components/_FilterDetailEntity.vue'
import FilterDetailEmployee from './components/_FilterDetailEmployee.vue'
import FilterDetailDate from './components/_FilterDetailDate.vue'
import FilterDetailNumber from './components/_FilterDetailNumber.vue'

export default {
  components: {
    FilterDetailText,
    FilterDetailSelect,
    FilterDetailEntity,
    FilterDetailEmployee,
    FilterDetailDate,
    FilterDetailNumber
  },
  props: {
    fieldFilters: {
      type: Array,
      default: () => []
    },
    selectedFilters: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // console.log(props.fieldFilters)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const fieldFiltersLocal = ref(props.fieldFilters)
    const selectedFiltersLocal = ref(props.selectedFilters)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const quickFiltersList = computed(() => {
      return props.fieldFilters.filter(f=>f.isQuickFilter)
    })

    const fieldFiltersList = computed(() => {
      return props.fieldFilters.filter(f=>!f.isQuickFilter)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.fieldFilters, val => {
      fieldFiltersLocal.value = val
    })

    watch(() => props.selectedFilters, val => {
      selectedFiltersLocal.value = val
    })

    watch(selectedFiltersLocal, val => {
      let quickFilter = quickFiltersList.value.find(qf=>val.includes(qf.name))
      if (quickFilter !== undefined) {
        updateFiltersLocal(quickFilter)
      }


      emit('update:selectedFilters', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateFiltersLocal = (filter) => {
      let i = fieldFiltersLocal.value.findIndex(ff=>filter.name == ff.name)

      if (filter.isQuickFilter) {
        fieldFiltersLocal.value[i].name = filter.name
        fieldFiltersLocal.value[i].filter = filter.value
      } else {
        fieldFiltersLocal.value[i] = filter
      }

      // console.log(fieldFiltersLocal.value)

      emit('updateFilters', fieldFiltersLocal.value)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      selectedFiltersLocal,
      fieldFiltersLocal,

      // Computed
      quickFiltersList,
      fieldFiltersList,

      // Methods
      updateFiltersLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.baseHeaderFilterDetails {
  .col-form-label {
    padding-bottom: 0px;
  }

  .checkBoxDetails {
    margin-left: 25px
  }
}
</style>