import { ref, computed, watch } from '@vue/composition-api'
import { useModuleView } from './useModuleView'
import { capitalize } from '../../utils/filter'

import ModuleViewFormPrompt from '../../components/prompt/ModuleView.vue'
import store from '../../store'
import { getUserData } from '../../utils/utils'
import router from '../../router'

export const mixinModuleViews = {
  components: {
    ModuleViewFormPrompt
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isModuleViewModalShow = ref(false)
    const currentModuleView = ref({})
    const viewParametersRef= ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedModuleView, removeModuleView } = useModuleView()

    // const toggleParameterDetailsOpen = (viewType, module) => {
    //   currentModuleView.value = JSON.parse(JSON.stringify(store.getters['moduleView/getDefaultViewByModule'](viewType, module)))
    //   currentModuleView.value.listColumns = currentModuleView.value.listColumns.filter(lc => lc.key != 'actions')
    //
    //   // console.log(currentModuleView.value)
    //   isModuleViewModalShow.value = true
    // }

    const addModuleView = (viewType, module) => {
      currentModuleView.value = JSON.parse(JSON.stringify(store.getters['moduleView/getEmptyModuleView']))
      currentModuleView.value.listColumns = currentModuleView.value.listColumns.filter(lc => lc.key != 'actions')
      currentModuleView.value.type = JSON.parse(JSON.stringify(viewType))
      currentModuleView.value.module = JSON.parse(JSON.stringify(module))

      isModuleViewModalShow.value = true
    }
    const deplicateModuleView = (moduleView) => {
      currentModuleView.value = JSON.parse(JSON.stringify(moduleView))
      currentModuleView.value.listColumns = currentModuleView.value.listColumns.filter(lc => lc.key != 'actions')
      delete currentModuleView.value.id

      isModuleViewModalShow.value = true
    }

    const editModuleView = (moduleView) => {
      currentModuleView.value = JSON.parse(JSON.stringify(moduleView))
      currentModuleView.value.listColumns = currentModuleView.value.listColumns.filter(lc => lc.key != 'actions')

      // console.log(currentModuleView.value)
      isModuleViewModalShow.value = true
    }

    const submitValidatedModuleViewLocal = () => {
      // console.log(currentModuleView.value)
      submitValidatedModuleView(currentModuleView.value)
        .then(() => {
          isModuleViewModalShow.value = false
          // resetSupplierInvoice()
        })
    }

    const setDefaultModuleView = ({ moduleView, isDefault }) => {
      currentModuleView.value = JSON.parse(JSON.stringify(moduleView))
      currentModuleView.value.listColumns = currentModuleView.value.listColumns.filter(lc => lc.key != 'actions')
      currentModuleView.value.isDefault = isDefault

      if (isDefault == true) {

        submitValidatedModuleView(currentModuleView.value)
          .then(() => {
            let userData = getUserData()
            userData.defaultViews[moduleView.module].id = moduleView.id
            userData.defaultViews[moduleView.module].type = moduleView.type
            localStorage.setItem('userData', JSON.stringify(userData))

            viewParametersRef.value.update()
            // router.go(0)
            // emit('update:selectedView', moduleView.type)

          })
      } else {

        submitValidatedModuleView(currentModuleView.value)
          .then(() => {
            let defaultView = store.getters['moduleView/getModuleViews'].find(mv=>{
              return mv.type == moduleView.type && mv.module == moduleView.module && mv._createdBy == null
            })

            let userData = getUserData()
            userData.defaultViews[moduleView.module].id = defaultView.id
            userData.defaultViews[moduleView.module].type = defaultView.type
            localStorage.setItem('userData', JSON.stringify(userData))

            viewParametersRef.value.update()
            // router.go(0)
            // emit('update:selectedView', moduleView.type)
          })
      }


    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isModuleViewModalShow,
      currentModuleView,
      viewParametersRef,

      // Computed

      // Methods
      // toggleParameterDetailsOpen,
      addModuleView,
      deplicateModuleView,
      editModuleView,
      removeModuleView,
      submitValidatedModuleViewLocal,
      setDefaultModuleView,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteModuleViewAlert (moduleView) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theModuleView') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeModuleView(moduleView)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}