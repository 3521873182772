<template>
  <div class="row">

    <div class="col-md-4">
      <select2
          :options="comparatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="comparatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-8">
      <b-row>
        <b-col :md="comparatorLocal.value=='between'?6:12">
          <date-field
              :model.sync="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isInputValueDisabled"
          />
        </b-col>
        <b-col
            v-if="comparatorLocal.value=='between'"
            md="6"
        >
          <date-field
              v-if="comparatorLocal.value == 'between'"
              :model.sync="inputValueLocal2"
              :min-date="inputValueLocal1"
              size="sm"
              class="my-auto flatPickrSmSize"
              :disabled="isInputValueDisabled"
          />
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import select2 from '../../../input/Select2.vue'
import DateField from '../../../input/Date.vue'
import i18n from '../../../../libs/i18n'
import InputField from '../../../input/Input.vue'

export default {
  components: {
    select2,
    DateField,
  },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const comparatorLocal = ref({
      value: props.field.filter.comparator,
      i18n: capitalize(i18n.t(props.field.filter.comparator))
    })

    const comparatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'before',
        i18n: capitalize(i18n.t('before'))
      },
      {
        value: 'after',
        i18n: capitalize(i18n.t('after'))
      },
      {
        value: 'between',
        i18n: capitalize(i18n.t('between'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const inputValueLocal1 = ref(props.field.filter.value)
    const inputValueLocal2 = ref(props.field.filter.value)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isInputValueDisabled = computed(() => {
      if (
          props.isDisabled ||
          (
              comparatorLocal.value != null &&
              (
                  comparatorLocal.value.value == 'is_empty' ||
                  comparatorLocal.value.value == 'is_not_empty'
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(comparatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        inputValueLocal1.value = null
        inputValueLocal2.value = null
      }

      parentUpdate()
    })

    watch(inputValueLocal1, val => {
      parentUpdate()
    })
    watch(inputValueLocal2, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      let fieldLocal = JSON.parse(JSON.stringify(props.field))

      if (['is_empty', 'is_not_empty'].includes(comparatorLocal.value.value)) {
        // console.log(comparatorLocal.value.value)
        fieldLocal.filter.comparator = comparatorLocal.value.value
        fieldLocal.filter.field = props.field.name
        fieldLocal.filter.value = null
        emit('updateFieldFilter', fieldLocal)

      } else if (comparatorLocal.value.value == 'between') {
        // console.log(comparatorLocal.value.value)
        if (
            inputValueLocal1.value != null &&
            inputValueLocal2.value != null
        ) {
          // console.log('Update !', inputValueLocal1.value, inputValueLocal1.value)
          fieldLocal.filter.comparator = comparatorLocal.value.value
          fieldLocal.filter.field = props.field.name
          fieldLocal.filter.value = [inputValueLocal1.value, inputValueLocal2.value]
          emit('updateFieldFilter', fieldLocal)

        }

      } else {
        // console.log(comparatorLocal.value.value, inputValueLocal1.value)
        if (inputValueLocal1.value != null) {
          // console.log('Update !', inputValueLocal1.value)
          fieldLocal.filter.comparator = comparatorLocal.value.value
          fieldLocal.filter.field = props.field.name
          fieldLocal.filter.value = inputValueLocal1.value
          emit('updateFieldFilter', fieldLocal)

        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      comparatorLocal,
      comparatorList,
      inputValueLocal1,
      inputValueLocal2,

      // Computed
      isInputValueDisabled,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.flatPickrSmSize {
  .form-control {
    height: 30.344px;
  }
}

</style>