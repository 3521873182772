<template>
  <div>
    <workflows-view/>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import WorkflowsView from '../../components/views/WorkFlowsViews.vue'

import useAPI from '../../utils/useAPI'
import store from '../../store'

export default {
  components: {
    WorkflowsView,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteArticles,
      fetchAutocompleteBankAccounts,
      fetchAutocompleteVats,
      fetchAutocompleteWorkflows,
      fetchAutocompleteEmployees,
      fetchAutocompleteCompanies
    } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    store.dispatch('moduleView/getModuleViews')
        .then(() => {
          fetchAutocompleteContacts()
          fetchAutocompletePaymentMethods()
          fetchAutocompleteArticles()
          fetchAutocompleteBankAccounts()
          fetchAutocompleteVats()
          fetchAutocompleteWorkflows()
          fetchAutocompleteEmployees()
          fetchAutocompleteCompanies()
        })

    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>