<template>
  <div class="row mb-2">
    <start-left
        :title="title"
        :items="items"
        :items-context="itemsContext"
        :display-custom-fields="displayCustomFields"
    />

    <start-right
        :selectedFilters="filters"
        :active-sorts.sync="activeSorts"
        :read-only="readOnly"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :is-add-buttons-loading="isAddButtonsLoading"
        :is-button-add-disabled="isButtonAddDisabled"
        :text-button-add="textButtonAdd"
        :module="module"
        :selected-view.sync="selectedViewLocal"
        :allow-view-settings="allowViewSettings"

        @updateFilters="updateFiltersLocal"
        @toggleFilterDetailsOpen="isFilterDetailsOpen = !isFilterDetailsOpen"
        @toggleSortDetailsOpen="isSortDetailsOpen = !isSortDetailsOpen"
        @toggleParameterDetailsOpen="isViewParametersDetailsOpen = !isViewParametersDetailsOpen"
        @addItem="$emit('addItem')"
        @addItemByUpload="$emit('addItemByUpload')"
    />

    <transition name="fade">
      <start-sort-details
          v-if="isSortDetailsOpen"
          :available-sort-fields="availableSortFields"
          :active-sorts.sync="activeSorts"
          class="mt-50"
      />
    </transition>

    <transition name="fade">
      <start-filter-details
          v-if="isFilterDetailsOpen"
          :field-filters="fieldFiltersLocal"
          :selected-filters.sync="selectedFieldFilters"
          class="mt-50"

          @updateFilters="updateFiltersLocal"
      />
    </transition>

    <transition name="fade">
      <start-view-parameters
          v-if="isViewParametersDetailsOpen"
          :module="module"
          class="mt-50"
          ref="viewParametersRef"

          @addModuleView="addModuleView(selectedView, module)"
          @editModuleView="editModuleView"
          @deplicateModuleView="deplicateModuleView"
          @deleteModuleView="deleteModuleViewAlert"
          @setDefaultModuleView="setDefaultModuleView"
          @resetFilters="resetFilters"

      />
    </transition>

    <module-view-form-prompt
        :module-view.sync="currentModuleView"
        :field-filters="fieldFilters"
        :items-context="itemsContext"
        :title="('id' in currentModuleView)?$t('EditModuleView'):$t('NewModuleView')"
        :is-open.sync="isModuleViewModalShow"
        @submitValidatedModuleView="submitValidatedModuleViewLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { mixinModuleViews } from '../mixinModuleViews'
import { capitalize } from '../../../utils/filter'
import { isEmptyObject } from '../../../utils/utils'

import StartLeft from './StartLeft.vue'
import StartRight from './StartRight.vue'
import StartFilterDetails from './FilterDetails.vue'
import StartSortDetails from './SortDetails.vue'
import StartViewParameters from './ViewParameters.vue'
import store from '../../../store'
import i18n from '../../../libs/i18n'

export default {
  components: {
    StartLeft,
    StartRight,
    StartFilterDetails,
    StartSortDetails,
    StartViewParameters,
  },
  mixins: [mixinModuleViews],
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    context: {
      type: Object,
      default: () => {}
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    selectedView: {
      type: String,
    },
    itemViewOptions: {
      type: Object,
      default: () => {}
    },
    module: {
      type: String,
      default: ''
    },
    defaultFilters: {
      type: Array,
      default: () => []
    },
    defaultSorts: {
      type: Array,
      default: () => []
    },
    fieldFilters: {
      type: Array,
      default: () => []
    },
    allowViewSettings: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isFilterDetailsOpen = ref(false)
    const isSortDetailsOpen = ref(false)
    const isViewParametersDetailsOpen = ref(false)
    const selectedFieldFilters = ref([])
    const filters = ref([])
    const activeSorts = ref(JSON.parse(JSON.stringify(props.defaultSorts)))
    const sorts = ref(props.defaultSorts)
    const apiFilters = ref(props.defaultFilters)
    const currentPayload = ref({})
    // const isInitialWatchIterator = ref(1)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const fieldFiltersLocal = computed({
      get () {
        // let fieldFiltersLocal = JSON.parse(JSON.stringify(props.fieldFilters))
        let fieldFiltersLocal = props.fieldFilters

        // console.log(JSON.parse(JSON.stringify(props.fieldFilters)))
        // console.log(props.fieldFilters)
        // console.log(JSON.parse(JSON.stringify(apiFilters.value)))

        apiFilters.value.forEach(defaultFilter => {
          // if (!('name' in defaultFilter) || defaultFilter.name == null) {
          defaultFilter.group.forEach(g => {
            let fieldIndex = fieldFiltersLocal.findIndex(f => (f.entity ? f.entity : f.name) === g.field)
            if (fieldIndex != -1) {
              fieldFiltersLocal[fieldIndex].filter = g
            }
          })
          //   // }
        })

        // console.log(JSON.parse(JSON.stringify(fieldFiltersLocal)))

        return fieldFiltersLocal
      },
      set (val) {
        // console.log(val)
        return val
      }
    })

    const selectedViewLocal = computed({
      get () {
        // console.log(props.selectedView)
        return props.selectedView
      },
      set (val) {
        // console.log(val)
        emit('update:selectedView', val)
      }
    })

    const availableSortFields = computed(() => {
      let availableSortFields = []
      props.fieldFilters.forEach(ff => {
        availableSortFields.push({
          value: ('entity' in ff) ? ff.entity : ff.name,
          display: capitalize(i18n.t(ff.name))
        })
      })

      return availableSortFields

      // return props.fieldFilters.map(ff => capitalize(i18n.t(ff.name)))
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.defaultFilters, val => {
      // console.log(val)
      apiFilters.value = val
      updateSelectedFieldFilters(val)
    })
    watch(() => props.defaultSorts, val => {
      sorts.value = val
    })
    watch(() => props.fieldFilters, (newValue, oldValue) => {
      // console.log(newValue, oldValue)
      if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
        updatePayload()
      }
    })

    watch(() => fieldFiltersLocal.value, val => {
          // console.log("A")
          // console.log(JSON.parse(JSON.stringify(val)))
          updateFiltersLocal(val)
        },
        { deep: true })

    watch(selectedFieldFilters, val => {
      // console.log("B")
      updateFiltersLocal(fieldFiltersLocal.value)
    })

    watch(() => filters.value, val => {
      // console.log(val)
      updatePayload()
    }, {
      deep: true
    })

    watch(() => sorts.value, val => {
      updatePayload()
    }, {
      deep: true
    })

    watch(selectedViewLocal, val => {
      let currentView = store.getters['moduleView/getDefaultViewByModule'](val, props.module)
      // console.log(val, props.module)

      updateSelectedFieldFilters(currentView.filters)
      apiFilters.value = currentView.filters

      updatePayload()
    })

    watch(() => props.defaultSorts, val => {
      activeSorts.value = JSON.parse(JSON.stringify(val))
    }, { deep: true })

    watch(() => activeSorts, val => {
      // console.log(val.value)
      // console.log('sorts change : ', val.value)

      updatePayload()
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateFiltersLocal = (transmittedFilters = []) => {
      // console.log('updateFilters', JSON.parse(JSON.stringify(transmittedFilters)))
      // console.log(JSON.parse(JSON.stringify(filters.value)))
      // console.log(JSON.parse(JSON.stringify(selectedFieldFilters.value)))
      let newFilters = [
        {
          groupOperator: 'AND',
          group: []
        }
      ]

      if (transmittedFilters.length == 1 && transmittedFilters[0].name == 'search') {
        // Filter bar

        let actuelFilters = JSON.parse(JSON.stringify(filters.value))

        let ffIndex = filters.value[0].group.findIndex(fg => fg.field === '_all')
        if (ffIndex != -1) {
          filters.value[0].group.splice(ffIndex, 1, JSON.parse(JSON.stringify(transmittedFilters[0].filter)))
        } else {
          filters.value = actuelFilters
          filters.value[0].group.splice(filters.value[0].group.length, 0, JSON.parse(JSON.stringify(transmittedFilters[0].filter)))
        }

      } else {
        // Filters

        let searchBarFilter
        if (filters.value.length && ('group' in filters.value[0])) {
          searchBarFilter = filters.value[0].group.find(fg => fg.field === '_all')
        }

        selectedFieldFilters.value.forEach(sf => {
          let ff = transmittedFilters.find(ff => {
            // console.log(ff.entity?ff.entity:ff.name, (ff.entity?ff.entity:ff.name) === sf)
            // if ('entity' in ff) {
            //   return ff.entity == sf
            // } else {
              return ff.name == sf
            // }
            // return (ff.entity?ff.entity:ff.name) === sf
          })
          // console.log(JSON.parse(JSON.stringify(transmittedFilters)))
          // console.log(sf, ff)
          if (ff && ('filter' in ff) && ff.filter && !isEmptyObject(ff.filter)) {
            // console.log(JSON.parse(JSON.stringify(ff)))
            newFilters[0].group.push(ff.filter)
          }
        })

        // console.log(JSON.parse(JSON.stringify(newFilters)))
        // console.log(JSON.parse(JSON.stringify(filters.value)))

        if (JSON.stringify(newFilters) != JSON.stringify(filters.value)) {
          filters.value = [
            {
              groupOperator: 'AND',
              group: searchBarFilter ? [JSON.parse(JSON.stringify(searchBarFilter))] : []
            }
          ]

          selectedFieldFilters.value.forEach(sf => {
            let ff = transmittedFilters.find(ff => ff.name === sf)
            if (ff && ('filter' in ff) && ff.filter && !isEmptyObject(ff.filter)) {

              filters.value[0].group.push(JSON.parse(JSON.stringify(ff.filter)))
            }
          })

          // console.log(JSON.parse(JSON.stringify(filters.value)))

        }
      }

    }

    const updateSelectedFieldFilters = (moduleViewFilters) => {
      selectedFieldFilters.value = []
      // console.log('updateSelectedFieldFilters')

      moduleViewFilters.forEach(defaultFilter => {
        defaultFilter.group.forEach(g => {
          // console.log(JSON.parse(JSON.stringify(g.field)))

          if (g.field.includes('_')) {
            selectedFieldFilters.value.push(g.field.split('_')[0])
          } else {
            selectedFieldFilters.value.push(g.field)
          }

        })
      })

      // console.log(JSON.parse(JSON.stringify(selectedFieldFilters.value)))
    }

    const updatePayload = () => {
      // console.log("Header update payload")
      let currentView = store.getters['moduleView/getDefaultViewByModule'](props.selectedView, props.module)
      let kanbanSortBy = (currentView && ('kanbanSortBy' in currentView)) ? currentView.kanbanSortBy : null
      let kanbanColumnAmount = (currentView && ('kanbanColumnAmount' in currentView)) ? currentView.kanbanColumnAmount : null
      let payloadSort = []
      activeSorts.value.forEach(as => {
        if (as.column != null) {
          payloadSort.push(as)
        }
      })

      let newPayload = {
        filters: (filters.value.length == 1 && filters.value[0].group.length == 0) ? [] : filters.value,
        sorts: payloadSort,
        kanbanSortBy: kanbanSortBy,
        kanbanColumnAmount: kanbanColumnAmount
      }

      if (
          JSON.stringify(newPayload) != JSON.stringify(currentPayload.value)
          // &&
          // props.fieldFilters.length
      ) {
        // console.log(newPayload)
        emit('updatePayload', newPayload)

        currentPayload.value = JSON.parse(JSON.stringify(newPayload))
      }
    }

    const listViewSort = (newSort) => {
      let fieldFilter = props.fieldFilters.find(ff => ff.name == newSort.column)

      if (fieldFilter !== undefined) {
        activeSorts.value = [{
          column: fieldFilter.entity ? fieldFilter.entity : fieldFilter.name,
          order: newSort.order
        }]
      } else {
        activeSorts.value = [newSort]
      }

    }

    const resetFilters = () => {
      filters.value = store.getters['moduleView/getDefaultViewByModule'](props.selectedView, props.module).filters
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // props.defaultSorts.forEach(ds => {
    //   activeSorts.pu
    // })

    return {
      // Components

      // Data
      isFilterDetailsOpen,
      isSortDetailsOpen,
      isViewParametersDetailsOpen,
      selectedFieldFilters,
      filters,
      activeSorts,
      sorts,

      // Computed
      fieldFiltersLocal,
      selectedViewLocal,
      availableSortFields,

      // Methods
      updateFiltersLocal,
      listViewSort,
      resetFilters,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>