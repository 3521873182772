<template>
  <div>
    <base-header
        :title="title"
        :items="items"
        :items-context="itemsContext"
        :context="context"
        :display-custom-fields="displayCustomFields"
        :read-only="readOnly"
        :allow-add="allowAdd"
        :allow-add-by-upload="allowAddByUpload"
        :is-add-buttons-loading="isAddButtonsLoading"
        :is-button-add-disabled="isButtonAddDisabled"
        :text-button-add="textButtonAdd"
        :module="module"
        :field-filters="fieldFilters"
        :default-filters="defaultFilters"
        :default-sorts="defaultSorts"
        :selected-view.sync="selectedView"
        :allow-view-settings="allowViewSettings"
        ref="headerRef"

        @updatePayload="$emit('updatePayload', $event)"
        @addItem="$emit('addItem')"
        @addItemByUpload="$emit('addItemByUpload')"
    />

    <kanban-view
        v-if="selectedView == 'kanban' && !openViewWindow"
        ref="kanbanView"
        :items="items"
        :current-view="currentView"
        :context="itemsContext"

        @tryToUpdateItem="$emit('tryToUpdateKanbanItem', $event)"
        @updatePayload="$emit('updatePayload', $event)"
    >
      <template #kanbanItemView="{item}">
        <slot
            name="kanbanItemView"
            :item="item"
        />
      </template>
    </kanban-view>

    <list-view
        v-if="selectedView == 'list' && !openViewWindow"
        ref="listViewRef"
        :items="items"
        :current-view="currentView"
        :is-action-column-enabled="isListViewActionColumnEnabled"
        :selectable="listViewSelectable"
        :allow-view-windows="allowViewWindows"

        @listSort="headerRef.listViewSort($event)"
        @rowSelected="$emit('selectedItems', $event)"
        @selectItem="$emit('showDetails', $event);openViewWindow=true"
        @showDetails="$emit('showDetails', $event);openViewWindow=true"
    >
      <template
          v-for="slot in slotsNames"
          v-slot:[slot]="{item}"
      >
        <slot
            :name="slot"
            :item="item"
        />
      </template>
    </list-view>

    <div
        v-if="openViewWindow"
        class="col-12"
    >
      <view-window
          :items="items"
          :open-view-window.sync="openViewWindow"
          :is-view-windows-ready="isViewWindowsReady"
      >
        <template #leftViewWindow="{item}">
          <slot
              name="leftViewWindow"
              :item="item"
          />
        </template>

        <template #title>
          <slot name="viewWindowTitle"/>
        </template>

        <template #buttons>
          <slot name="viewWindowButtons"/>
        </template>

        <template #windowView>
          <slot name="windowView"/>
        </template>
      </view-window>
    </div>


    <pagination
        v-if="selectedView != 'kanban'"
        :context="itemsContext"

        @updatePayload="$emit('updatePayload', $event)"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import BaseHeader from './header/Header.vue'

import KanbanView from './views/KanbanView.vue'
import ListView from './views/ListView.vue'
import store from '../../store'
import ViewWindow from '../base/views/ViewWindow.vue'
import StartRight from './header/StartRight.vue'
import Pagination from './footer/Pagination.vue'

export default {
  components: {
    Pagination,
    StartRight,
    ViewWindow,
    BaseHeader,
    KanbanView,
    ListView
  },
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    allowViewWindows: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    context: {
      type: Object,
      default: () => {}
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
    defaultFilters: {
      type: Array,
      default: () => []
    },
    defaultSorts: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: ''
    },
    fieldFilters: {
      type: Array,
      default: () => []
    },
    isListViewActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    listViewSelectable: {
      type: String,
      default: null
    },
    allowViewSettings: {
      type: Boolean,
      default: true
    },
    isViewWindowsReady: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const kanbanView = ref()
    const selectedView = ref(null)
    const headerRef = ref()
    const listViewRef = ref()
    const openViewWindow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentView = computed(() => {
      // console.log(store.getters['moduleView/getDefaultViewByModule'](selectedView.value, props.module))
      return store.getters['moduleView/getDefaultViewByModule'](selectedView.value, props.module)
    })

    const slotsNames = computed(() => {
      let slotsNames = []
      if (currentView.value && ('listColumns' in currentView.value)) {
        currentView.value.listColumns.forEach(lc => {
          slotsNames.push('listView_head_' + lc.key)
          slotsNames.push('listView_cell_' + lc.key)
        })
      }

      return slotsNames
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const closeViewWindow = () => {
      openViewWindow.value = false
      emit('update:isViewWindowsReady', false)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      kanbanView,
      selectedView,
      headerRef,
      listViewRef,
      openViewWindow,

      // Computed
      currentView,
      slotsNames,

      // Methods
      closeViewWindow,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>