<template>
  <div>
    <b-row>

      <!--      Name-->
      <b-col md="9">
        <field-input
            :model.sync="moduleViewLocal.name"
            name="name"
            rules="required"
        />
      </b-col>

      <!--      Default-->
      <b-col md="3">
        <field-switch
            :model.sync="moduleViewLocal.isDefault"
            name="byDefault"
        />
      </b-col>

      <!--      Module-->
      <b-col md="6">
        <field-select
            name="module"
            :model.sync="moduleViewLocal.module"
            :options="moduleList"
            label="display"
            reduce="module"
        />
      </b-col>

      <!--      Type-->
      <b-col md="6">
        <field-select
            name="viewType"
            :model.sync="moduleViewLocal.type"
            :options="viewTypeList"
            label="display"
            reduce="viewType"
        />

      </b-col>


      <b-col
          md="12"
          class="mt-1"
      >

        <b-tabs fill>

          <!--          View-->
          <b-tab>
            <template #title>
              <icon icon="eye"/>
              <span>{{ capitalize($t('view')) }}</span>
            </template>

            <!--            List-->
            <field-list-order
                v-show="moduleViewLocal.type == 'list'"
                name="columns"
                :placeholder="capitalize($t('addField'))"
                :model.sync="moduleViewLocal.listColumns"
                :list="viewTypeListColumns"
                label="title"
                class="mb-2"
                ref="fieldListOrder"
            >
              <template #itemView="{item, index}">
                <div class="w-100 d-flex justify-content-between">
                  <icon
                      icon="bars"
                      class="my-auto mx-1"
                  />
                  <div class="w-100 my-auto">{{ capitalize($t(item.title)) }}</div>
                  <button-delete
                      class="mx-1"
                      :with-icon="true"
                      :with-text="false"
                      size="sm"

                      @click="$refs.fieldListOrder.removeToModel(index)"
                  />
                </div>

              </template>
            </field-list-order>

            <!--            Kanban-->
            <b-row
                v-if="moduleViewLocal.type == 'kanban'"
            >
              <b-col md="6">
                <field-select
                    name="classifyBy"
                    :model.sync="moduleViewLocal.kanbanSortBy"
                    :options="listKanbanSortBy"
                    label="display"
                    reduce="sortBy"
                    :is-required="true"
                />
              </b-col>
              <b-col md="6">
                <field-select
                    name="aggregateBy"
                    :model.sync="moduleViewLocal.kanbanColumnAmount"
                    :options="kanbanColumnAmountList"
                    label="display"
                    reduce="value"
                />
              </b-col>
            </b-row>

          </b-tab>

          <!--          Filters-->
          <b-tab>
            <template #title>
              <icon icon="filter"/>
              <span>{{ capitalize($tc('filter', 2)) }} ({{ numberOfFilters }})</span>
            </template>

            <filter-details
                :field-filters="fieldFiltersLocal"
                :selected-filters.sync="selectedFieldFilters"
                class="mt-50"

                @updateFilters="updateFiltersLocal"
            />
          </b-tab>

          <!--          Sorts-->
          <b-tab>
            <template #title>
              <icon icon="arrows-alt-v"/>
              <span>{{ capitalize($t('sort')) }} ({{ moduleViewLocal.sorts.length }})</span>
            </template>

            <sort-details
                :available-sort-fields="availableSortFields"
                :active-sorts.sync="moduleViewLocal.sorts"
                class="mt-50"
            />
          </b-tab>

        </b-tabs>


      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'
import { isEmptyObject } from '../../utils/utils'

import i18n from '../../libs/i18n'

import FieldInput from '../input/Input.vue'
import FieldSwitch from '../input/Switch.vue'
import FieldSelect from '../input/Select2.vue'
import FieldListOrder from '../input/ListOrder.vue'
import ButtonDelete from '../button/Delete.vue'
import FilterDetails from '../base2/header/FilterDetails.vue'
import SortDetails from '../base2/header/SortDetails.vue'
import store from '../../store'

export default {
  components: {
    FieldInput,
    FieldSwitch,
    FieldSelect,
    FieldListOrder,
    ButtonDelete,
    FilterDetails,
    SortDetails
  },
  props: {
    moduleView: {
      type: Object,
      default: () => {}
    },
    fieldFilters: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.moduleView)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const moduleList = ref([
      {
        module: 'crm',
        display: capitalize(i18n.t('CRM'))
      }
    ])
    const viewTypeList = ref([
      {
        viewType: 'list',
        display: capitalize(i18n.t('list'))
      },
      {
        viewType: 'kanban',
        display: capitalize(i18n.t('kanban'))
      },
    ])
    const fieldListOrder = ref()
    const selectedFieldFilters = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const moduleViewLocal = computed({
      get () {
        return props.moduleView
      },
      set (val) {
        emit('update:moduleView', val)
      }
    })

    const fieldFiltersLocal = computed({
      get () {
        let fieldFiltersLocal = JSON.parse(JSON.stringify(props.fieldFilters))

        props.moduleView.filters.forEach(defaultFilter => {
          // if (!('name' in defaultFilter) || defaultFilter.name == null) {
          defaultFilter.group.forEach(g => {
            let fieldIndex = fieldFiltersLocal.findIndex(f => f.name === g.field)
            if (fieldIndex != -1) {
              fieldFiltersLocal[fieldIndex].filter = g
            }
          })
          // }
        })

        return fieldFiltersLocal
      },
      set (val) {
        // return val
      }
    })

    const numberOfFilters = computed(() => {
      if (moduleViewLocal.value.filters.length) {
        return moduleViewLocal.value.filters[0].group.length
      } else {
        return 0
      }
    })

    const availableSortFields = computed(() => {
      let availableSortFields = []
      props.fieldFilters.forEach(ff => {
        availableSortFields.push({
          value: ('entity' in ff) ? ff.entity : ff.name,
          display: capitalize(i18n.t(ff.name))
        })
      })

      return availableSortFields
    })

    const listKanbanSortBy = computed(() => {
      let listKanbanSortBy = []
      props.fieldFilters.forEach(ff => {
        if ('entity' in ff) {
          listKanbanSortBy.push({
            display: capitalize(i18n.t(ff.name)),
            sortBy: ff.entity
          })
        } else {
          listKanbanSortBy.push({
            display: capitalize(i18n.t(ff.name)),
            sortBy: ff.name
          })
        }
      })

      return listKanbanSortBy
    })

    const kanbanColumnAmountList = computed(() => {
      let kanbanColumnAmountList = []

      // console.log(props.itemsContext)

      for (const [field, type] of Object.entries(props.itemsContext.fields_filters)) {
        if (type == 'decimal') {
          kanbanColumnAmountList.push({
            display: capitalize(i18n.t(field)),
            value: field
          })
        }
      }

      return kanbanColumnAmountList
    })

    const viewTypeListColumns = computed(() => {
      let module = moduleViewLocal.value.module
      if (moduleViewLocal.value.module == 'crm') {
        module = 'workFlo'
      }
      return store.getters[module + '/getAvailableListColumns']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.moduleView, val => {
      updateSelectedFieldFilters()
    })

    watch(selectedFieldFilters, val => {
      updateFiltersLocal(fieldFiltersLocal.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateSelectedFieldFilters = () => {
      selectedFieldFilters.value = []

      props.moduleView.filters.forEach(defaultFilter => {
        // if (!('name' in defaultFilter) || defaultFilter.name == null) {
        defaultFilter.group.forEach(g => {
          selectedFieldFilters.value.push(g.field)
        })
        // }
      })
    }

    const updateFiltersLocal = (transmittedFilters = []) => {
      let newFilters = {
        groupOperator: 'AND',
        group: []
      }

      selectedFieldFilters.value.forEach(sf => {
        let ff = transmittedFilters.find(ff => ff.name === sf)
        if (ff && ('filter' in ff) && ff.filter && !isEmptyObject(ff.filter)) {
          newFilters.group.push(ff.filter)
        }
      })

      moduleViewLocal.value.filters[0] = newFilters
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateSelectedFieldFilters()

    return {
      // Components
      capitalize,

      // Data
      moduleList,
      viewTypeList,
      fieldListOrder,
      selectedFieldFilters,

      // Computed
      moduleViewLocal,
      fieldFiltersLocal,
      numberOfFilters,
      availableSortFields,
      listKanbanSortBy,
      kanbanColumnAmountList,
      viewTypeListColumns,

      // Methods
      updateFiltersLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>