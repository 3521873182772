<template>
  <b-row>
    <b-col sm="12">
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-2">
          <select2
              :model.sync="activeSortLocal.column"
              :options="availableSortFields"
              :clearable="false"
              reduce="value"
              label="display"
              size="sm"
              name="sort"
              :display-label="false"
          />
        </div>

        <div class="w-100 mr-2">
          <select2
              :model.sync="activeSortLocal.order"
              :options="availableOrders"
              reduce="value"
              label="display"
              size="sm"
          />
        </div>

        <div style="padding-top: 2px;">
          <button-delete
              size="sm"
              :with-icon="true"
              :with-text="false"

              @click="$emit('removeSort')"/>
        </div>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Select2 from '../../../input/Select2.vue'
import ButtonDelete from '../../../../components/button/Delete.vue'
import { capitalize } from '../../../../utils/filter'
import i18n from '../../../../libs/i18n'

export default {
  components: {
    Select2,
    ButtonDelete
  },
  props: {
    availableSortFields: {
      type: Array,
      default: () => []
    },
    activeSort: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const availableOrders = ref([
      {
        value: 'DESC',
        display: capitalize(i18n.t('descendingOrder'))
      },
      {
        value: 'ASC',
        display: capitalize(i18n.t('ascendingOrder'))
      }]
    )

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const activeSortLocal = computed({
      get () {
        return props.activeSort
      },
      set (val) {
        emit('update:activeSort', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      availableOrders,

      // Computed
      activeSortLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>