<template>
  <div class="row">

    <div class="col-md-4">
      <select2
          :options="comparatorList"
          :displayLabel="false"
          size="sm"
          :model.sync="comparatorLocal"
          :clearable="false"
          :disabled="isDisabled"
          label="i18n"
      />
    </div>

    <div class="col-md-8">
      <select2
          :options="entityList"
          :displayLabel="false"
          size="sm"
          :model.sync="selectedValueLocal"
          :disabled="isSelectedValueDisabled"
          :multiple="field.multiple"
          :label="field.entityLabel"
          reduce="id"
      />
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import select2 from '../../../input/Select2.vue'
import { capitalize } from '../../../../utils/filter'
import i18n from '../../../../libs/i18n'
import useAPI from '../../../../utils/useAPI'
import store from '../../../../store'

export default {
  components: { select2 },
  props: {
    field: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const comparatorLocal = ref({
      value: props.field.filter.comparator,
      i18n: capitalize(i18n.t(props.field.filter.comparator))
    })

    const comparatorList = ref([
      {
        value: 'equal',
        i18n: capitalize(i18n.t('equal'))
      },
      {
        value: 'not_equal',
        i18n: capitalize(i18n.t('not_equal'))
      },
      {
        value: 'is_empty',
        i18n: capitalize(i18n.t('is_empty'))
      },
      {
        value: 'is_not_empty',
        i18n: capitalize(i18n.t('is_not_empty'))
      },
    ])

    const selectedValueLocal = ref(props.field.filter.value)
console.log(props.field.filter.value)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isSelectedValueDisabled = computed(() => {
      if (
          props.isDisabled ||
          (
              comparatorLocal.value != null &&
              (
                  comparatorLocal.value.value == 'is_empty' ||
                  comparatorLocal.value.value == 'is_not_empty'
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    const entityList = computed(() => {
      return store.getters[props.field.entityStore]
      // return store.getters['bankAccount/getAutocompleteBankAccounts']
    })


    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(comparatorLocal, val => {
      if (
          val != null &&
          (
              val.value == 'is_empty' ||
              val.value == 'is_not_empty'
          )) {
        selectedValueLocal.value = null
      }

      parentUpdate()
    })

    watch(() => props.field, val => {
      console.log(val)
    }, {deep: true})

    watch(selectedValueLocal, val => {
      parentUpdate()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const parentUpdate = () => {
      if (isSelectedValueDisabled.value) {
        let fieldLocal = props.field
        fieldLocal.filter.comparator = comparatorLocal.value.value
        fieldLocal.filter.field = props.field.name
        fieldLocal.filter.value = null

        emit('updateFieldFilter', fieldLocal)
      } else if (
          comparatorLocal.value.value != null &&
          selectedValueLocal.value != null
      ) {
        let fieldLocal = props.field
        fieldLocal.filter.comparator = comparatorLocal.value.value
        fieldLocal.filter.field = props.field.entity ? props.field.entity : props.field.name
        fieldLocal.filter.value = selectedValueLocal.value

        emit('updateFieldFilter', fieldLocal)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      comparatorLocal,
      comparatorList,
      selectedValueLocal,

      // Computed
      isSelectedValueDisabled,
      entityList

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>