<template>
  <div class="col-md-4 mb-1 mb-md-0">
    <div class="d-flex">
      <h2 class="my-0">
        {{ title != '' ? capitalize($t(title)) : capitalize($t($route.name)) }}
      </h2>
      <small class="ml-2 my-auto">
        {{ totalResults }} {{ $tc('resultFound', totalResults) }}
      </small>
    </div>

<!--    <div>YYYY</div>-->

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'

export default {
  components: {},
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsContext: {
      type: Object,
      default: () => {}
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const totalResults = computed(() => {
      return ('total_results' in props.itemsContext)?props.itemsContext.total_results:0
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      totalResults,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>