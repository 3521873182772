<template>
  <div>
    <base-template
        :title="$t('workflows')"
        module="workflows"
        :field-filters="$store.getters['workflow/getFieldFilters']"
        :default-filters="defaultView?defaultView.filters:[]"
        :default-sorts="defaultView?defaultView.sorts:[]"
        :items="workflows"
        :items-context="workflowsContext"
        :is-list-view-action-column-enabled="true"
        ref="baseTemplate"

        @updatePayload="$store.dispatch('workflow/getWorkflows', $event)"
        @showDetails="$router.push({ name: 'Workflow view', params: { workflowId: $event.id } })"
        @addItem="addWorkflow"
    >
      <template #listView_cell_contact="{item}">
        {{
          item.customerCompany ? item.customerCompany._display : (item.customerIndividual ? item.customerIndividual._display : '-')
        }}
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editWorkflowLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Duplicate -->
        <button-duplicate
            @click.native.stop="duplicateWorkflow(item)"
            v-b-tooltip.hover.left="$t('duplicate')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteWorkflowAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :is-disabled="item.number ==null"
            size="sm"
        />

      </template>

    </base-template>

    <!-- modal workflow-->
    <modal-workflow
        :workflow.sync="currentWorkflow"
        :title="workflowModalTitle"
        :isOpen.sync="workflowModalShow"
        @submitValidatedWorkflow="submitValidatedWorkflowLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { getRoute, getUserData } from '../../utils/utils'
import { useWorkflows } from '../../views/workflows/useWorkflows'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import store from '../../store'
import i18n from '../../libs/i18n'

import ButtonRemove from '../button/Remove.vue'
import BaseTemplate from '../base2/Base.vue'
import ButtonEdit from '../button/Edit.vue'
import ButtonDuplicate from '../button/Duplicate.vue'
import ModalWorkflow from '../prompt/Workflow.vue'

export default {
  components: { ButtonEdit, BaseTemplate, ButtonRemove, ButtonDuplicate, ModalWorkflow, ModalWorkflow },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const displayedWorkflow = ref(null)
    const currentWorkflow = ref(null)
    const workflowModalShow = ref(true)
    const workflowModalTitle = ref(i18n.t('NewWorkflow'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows, workflowsContext } = useAPI()

    const defaultView = computed(() => {
      return store.getters['moduleView/getModuleView'](getUserData().defaultViews.workflows.id)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      submitValidatedWorkflow,
      removeWorkflow,
    } = useWorkflows()

    const resetWorkflow = () => {
      workflowModalTitle.value = i18n.t('NewWorkflow')
      currentWorkflow.value = JSON.parse(JSON.stringify(store.getters['workflow/getEmptyWorkflow']))
    }

    const addWorkflow = () => {
      currentWorkflow.value.responsible = store.getters['user/getMe']

      workflowModalShow.value = true
    }

    const editWorkflowLocal = (workflow) => {
      workflowModalTitle.value = i18n.t('EditWorkflow')
      store.dispatch('workflow/getWorkflow', workflow.id)
          .then(response => {
            currentWorkflow.value = response
            workflowModalShow.value = true
          })
    }

    const removeWorkflowLocal = (workflow) => {
      removeWorkflow(workflow)
          .then(response => {

          })
    }

    const submitValidatedWorkflowLocal = () => {
      // console.log(currentWorkflow.value)
      submitValidatedWorkflow(currentWorkflow.value)
          .then(response => {
            resetWorkflow()
            workflowModalShow.value = false
          })
    }

    const duplicateWorkflow = (workflow) => {
      store.dispatch('workflow/getWorkflow', workflow.id).then((workflow) => {
        currentWorkflow.value = workflow
        delete currentWorkflow.value.id
        delete currentWorkflow.value.logs
        delete currentWorkflow.value.events
        delete currentWorkflow.value.incomingInvoices
        delete currentWorkflow.value.offers
        delete currentWorkflow.value.orders
        delete currentWorkflow.value.outgoingInvoices

        workflowModalShow.value = true
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetWorkflow()

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      displayedWorkflow,
      currentWorkflow,
      workflowModalShow,
      workflowModalTitle,

      // Computed
      workflows,
      workflowsContext,
      defaultView,

      // Methods
      addWorkflow,
      editWorkflowLocal,
      removeWorkflowLocal,
      submitValidatedWorkflowLocal,
      duplicateWorkflow,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteWorkflowAlert (workflow) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theWorkflow') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeWorkflowLocal(workflow)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>